<template>
  <fragment>
    <!-- <div class="box_g box_status"> -->
    <div class="head_tbl">
      <div class="pos_left">
        <strong class="tit_text">결재현황</strong>
      </div>
    </div>
    <div class="area_status">
      <!-- 2019-07-02 수정 시작 -->
      <ul class="list_status">
        <li
          v-for="line in approvalLineWithReasonList"
          :key="line.aprvlOrder"
          :class="makeApprLiClass(line)"
        >
          <div class="item_status">
            <em class="tit_approval">{{ aprvlLineFlag[line.aprvlLineType] }}</em>
            <div class="txt_nick">{{ line.empName }} ({{ line.accountId }})</div>
            <em class="screen_out">부서명</em>
            <div class="txt_team">
              {{ line.deptName }}
            </div>
            <em class="screen_out">결재 상태</em>
            <!-- class="ico_account" -->
            <div :class="['tag_status', makeApprStatusClass(line, false)]">
              {{ makeApprStatusName(line, false) }}
            </div>
            <em class="screen_out">결재 일시</em>
            <div v-if="line.aprvlStatus === approvalStatus.APPROVAL_PAYMENT" class="txt_time">
              {{ line.regDate | strDateTimeSplit }}
            </div>
            <div class="ico_account ico_arr" />
          </div>

          <div v-if="line.delegateEmpNo != null && line.delegateEmpNo !== ''" class="item_status">
            <em class="tit_approval">위임자</em>
            <div class="txt_nick">{{ line.delegateEmpName }} ({{ line.delegateAccountId }})</div>
            <em class="screen_out">부서명</em>
            <div class="txt_team">
              {{ line.delegateDeptName }}
            </div>
            <em class="screen_out">결재 상태</em>
            <!-- class="ico_account" -->
            <div :class="['tag_status', makeApprStatusClass(line, true)]">
              {{ makeApprStatusName(line, true) }}
            </div>
            <em class="screen_out">결재 일시</em>
            <div class="txt_time">
              {{ line.apprDatetime | strDateTimeSplit }}
            </div>
            <div class="ico_account ico_arr" />
          </div>

          <div v-if="line.holdYn === 'Y'" class="item_status">
            <em class="tit_approval">보류</em>
            <div class="txt_period">~{{ line.delegateToDate | strDateTimeSplit }}</div>
            <div class="info_reason">
              <a href="#none" class="link_reason">보류사유</a>
              <div class="box_reason">
                {{ line.delegateReason }}
              </div>
            </div>
            <em class="screen_out">결재 상태</em>
            <div class="ico_account" />
            <em class="screen_out">결재 일시</em>
            <div class="txt_time" />
            <div class="ico_account ico_arr" />
          </div>
        </li>
      </ul>
    </div>
    <!-- // area_status -->
    <!--      <div class="wrap_btn" v-if="canApproveEdit">-->
    <!--        <button type="button" class="btn_comm bg_type3 type_small" @click.prevent="searchOrganization">상위결재자 변경</button>-->
    <!--      </div>-->
    <!-- </div> -->
    <!-- 참조자 목록 -->
    <div class="box_g box_refer">
      <strong class="screen_out">참조자 목록</strong>
      <dl class="list_addition">
        <dt>참조자</dt>
        <dd>
          <fragment v-for="(line, idx) in referencerList" :key="idx">
            <span v-if="idx !== 0" class="txt_comma">,</span>
            {{ line.deptName }} {{ line.empName }} ({{ line.accountId }})
          </fragment>
        </dd>
      </dl>
    </div>
    <!-- 열람자 목록 -->
    <pay-status-viewer
      v-if="isViewer"
      :items="viewerList"
      :is-viewer-edit="true"
      @delete="(viewer) => $emit('popDeleteViewer', viewer)"
    />
    <!-- 열람자 목록 -->
    <!-- <div v-if="isViewer" class="box_addition">
      <strong class="screen_out">열람자 목록</strong>
      <dl class="list_addition">
        <dt>열람자</dt>
        <dd>
          <div class="area_addition">
            <div
              class="group_addition"
              v-for="viewer in viewerList"
              :key="viewer.empNo"
            >
              <span class="txt_addition"
                >{{ viewer.accountId }} ({{ viewer.empName }})
                {{ viewer.insertDatetime | moment(dateTimeFormat) }}</span
              >
              <button
                type="button"
                class="link_del"
                @click="$emit('popDeleteViewer',viewer)"
              >
                <span class="ico_account">삭제</span>
              </button>
            </div>
          </div>
        </dd>
      </dl>
    </div> -->
    <!-- 추가 결재자 목록 -->
    <div v-if="isApprover" class="box_addition">
      <strong class="screen_out">추가 결재자 목록</strong>
      <dl class="list_addition">
        <dt>추가 결재자</dt>
        <dd>
          <div class="area_addition">
            <div
              v-for="(addApprover, index) in addApproverList"
              :key="index"
              class="group_addition"
            >
              <span class="txt_addition"
                >{{ addApprover.empName }} ({{ addApprover.accountId }})
                {{ addApprover.insertDatetime | strDateTimeSplit }}</span
              ><!-- href에 파일 경로 삽입 -->
              <button
                type="button"
                class="link_del"
                @click="$emit('popDeleteapprover', addApprover)"
              >
                <span class="ico_account">삭제</span>
              </button>
            </div>
          </div>
        </dd>
      </dl>
    </div>
  </fragment>
</template>

<script>
import { commConst } from "@/_approval/const/const.js";
import { default as CommLayerMixin } from "@/_approval/mixins/common/commLayer.js";
import { default as CommToastMixin } from "@/_approval/mixins/common/commToast.js";
import { default as CommMomentMixin } from "@/_approval/mixins/common/commMoment.js";
import { Fragment } from "vue-fragment";
import { aprvlLineType, approvalStatus } from "@/_approval/const/const";
import PayStatusViewer from "@/_approval/components/shared/view/payStatus/PayStatusViewer";

export default {
  name: "PayStatus",
  components: { Fragment, PayStatusViewer },
  mixins: [CommLayerMixin, CommToastMixin, CommMomentMixin],
  props: {
    docFlag: String,
    docNo: String,
    approvalLine: Array,
    apprType: String,
    addApproverList: Array,
    viewerList: Array,
    canApproveEdit: { type: Boolean, default: false },
    isApprover: { type: Boolean, default: false },
    isViewer: { type: Boolean, default: false },
    isViewerEdit: { type: Boolean, default: false },
    dateTimeFormat: String,
  },
  data() {
    return {
      aprvlLineType: aprvlLineType,
      approvalStatus: approvalStatus,
      aprvlLineFlag: {
        [aprvlLineType.DRAFT]: "기안자",
        [aprvlLineType.APPROVAL]: "승인자",
        [aprvlLineType.REFERENCE]: "참조자",
        [aprvlLineType.VIEWER]: "열람자",
      },
      approvalLineWithReason: [],
    };
  },
  computed: {
    datetimeFormat: function () {
      return commConst.DATETIME_FORMAT;
    },
    referencerList: function () {
      return this.approvalLine.filter(function (line) {
        return line.aprvlLineType === aprvlLineType.REFERENCE;
      });
    },
    approvalLineWithReasonList() {
      return this.approvalLine.filter(function (line) {
        return (
          line.aprvlLineType === aprvlLineType.DRAFT ||
          line.aprvlLineType === aprvlLineType.APPROVAL
        );
      });
    },
  },
  watch: {
    approvalLine(approvalLine) {},
  },
  mounted() {
    this.updateReadYn();
  },
  methods: {
    makeApprStatusClass: function (line, isDelegate) {
      if (!isDelegate && line.delegateEmpNo != null && line.delegateEmpNo !== "") {
        return "tag_instead"; // 대결
      } else if (line.holdYn === "Y") {
        return "tag_hold"; // 보류
      } else if (line.aprvlStatus === approvalStatus.APPROVAL_PAYMENT) {
        return "tag_approval"; // 승인
      } else if (
        line.aprvlStatus === approvalStatus.REJECT ||
        line.aprvlStatus === approvalStatus.DRAFT_REJECT
      ) {
        return "tag_return"; // 반려
      } else if (line.aprvlLineType === aprvlLineType.DRAFT) {
        return "tag_report"; // 상신
      } else {
        return "tag_before";
      }
    },
    makeApprStatusName: function (line, isDelegate) {
      if (!isDelegate && line.delegateEmpNo != null && line.delegateEmpNo !== "") {
        return "대결"; // 대결
      } else if (line.holdYn === "Y") {
        return "보류"; // 보류
      } else if (line.aprvlStatus === approvalStatus.APPROVAL_PAYMENT) {
        return "승인"; // 승인
      } else if (
        line.aprvlStatus === approvalStatus.REJECT ||
        line.aprvlStatus === approvalStatus.DRAFT_REJECT
      ) {
        return "반려"; // 반려
      } else if (line.aprvlLineType === aprvlLineType.DRAFT) {
        return "상신"; // 상신
      } else {
        return "";
      }
    },
    // 참조자 리스트만 필터링
    makeApprLiClass: function (item) {
      let classArray = [];
      if (
        (item.aprvlLineType === this.aprvlLineType.DRAFT ||
          item.aprvlStatus === "A" ||
          item.aprvlStatus === "J" ||
          item.aprvlStatus === "R") &&
        item.aprvlStatus !== "W"
      ) {
        classArray.push("done_on");
      }
      if ((item.delegateEmpNo != null && item.delegateEmpNo !== "") || item.holdYn === "Y") {
        classArray.push("instead_on");
      }
      return classArray;
    },

    updateReadYn: function () {},
    searchOrganization: function () {
      this.$emit("search-organization");
    },
  },
};
</script>
