export const commConst = Object.freeze({
  DATETIME_FORMAT: "YYYY-MM-DD HH:mm",
  DATE_FORMAT: "YYYY-MM-DD",
  DKT_CMP_CODE: "dkt",
  ACTOR_FLAG: {
    APPROVAL: "APPROVAL",
    DRAFT: "DRAFT",
    REFERENCE: "REFERENCE",
    VIEWER: "VIEWER",
  },
});

export const serviceConst = Object.freeze({
  DATE_FORMAT: "YYYY-MM-DD",
});

export const aprvlLineType = Object.freeze({
  /** * 기안자 */
  DRAFT: "D",
  /** * 결제자 */
  APPROVAL: "A",
  /** * 참조자 */
  REFERENCE: "R",
  /** * 열람자 */
  VIEWER: "V",
});

export const assetType = Object.freeze({
  // 총무자산
  GENERAL_ASSET: "G",
  // 자산구매
  INFRA_ASSET: "I",
  // 일반구매
  SERVICE_AND_GENERAL_ASSET: "H",
});

export const assetTypeName = Object.freeze({
  [assetType.GENERAL_ASSET]: "총무자산",
  [assetType.SERVICE_AND_GENERAL_ASSET]: "일반구매",
  [assetType.INFRA_ASSET]: "자산구매",
});

export const accountType = Object.freeze({
  // 선택안함
  NONE: "N",
  // 1회정산
  ONCE: "O",
  // 정기정산
  REGULARLY: "R",
});

export const docStatus = Object.freeze({
  // 임시저장
  TEMPORARY: "T",
  // 진행중
  PROCESSING: "I",
  // 완료
  COMPLETED: "C",
  // 반려
  REJECT: "J",
});

export const targetGubun = Object.freeze({
  //테스트
  TESTDFLT: "DFLT",

  // 구매 요청서
  DF_PURCHASE_REQUEST_WF: "DPRW",
  // 기안서
  DF_SETTLEMENT: "DS",

  // 구매시스템 표준화
  STANDARD: "STD",
});

// 결제 상태
export const approvalStatus = Object.freeze({
  // 결제전
  BEFORE_PAYMENT: "W",
  // 수신 (결제 자신 차례)
  RECEIVE_PAYMENT: "R",
  // 결제 승인
  APPROVAL_PAYMENT: "A",
  // 전체 반려 처리
  REJECT: "J",
  // 기안자 반려 처리
  DRAFT_REJECT: "D",
  // 이전 결재자 반려 처리
  DRAFT_BEFORE_REJECT: "B",
  // 결재 대상 아님
  NONE: "N",
  // 전체
  ALL: "ALL",
});

export const invalidFiles = {
  INVALID_FILE_EXTENSIONS: [".ico", ".exe", ".dmg", ".gif"],
};
