<template>
  <div class="popup_layer">
    <div class="inner_popup_layer" style="width: 628px">
      <div class="layer_head">
        <strong class="tit_popup">정산서 내역보기</strong>
      </div>
      <div class="layer_body">
        <div class="tbl_comm tbl_list tbl_scroll">
          <div
            class="inner_scroll"
            style="height: calc(100vh - 316px); max-height: 343px; min-height: 49px"
          >
            <table>
              <colgroup>
                <col style="width: 192px" />
                <!-- 기안번호 -->
                <col style="width: 176px" />
                <!-- 기안자 -->
                <col />
                <!-- 정산금액 -->
              </colgroup>
              <thead>
                <tr>
                  <th style="width: 192px">기안번호</th>
                  <th style="width: 176px">기안자</th>
                  <th style="width: 196px">정산금액</th>
                </tr>
              </thead>
              <tbody>
                <PopStatementHistoryListLine
                  v-for="(item, index) in dataList"
                  v-if="dataList && dataList.length > 0"
                  :key="index"
                  :rowData="item"
                />
                <tr v-if="!dataList || dataList.length === 0">
                  <td colspan="3">내역이 존재하지 않습니다.</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="layer_foot">
        <div class="wrap_btn">
          <button
            type="button"
            class="btn_medium btn_fourthly"
            @click.prevent="$emit('onClickClose')"
          >
            닫기
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PopStatementHistoryListLine from "./PopStatementHistoryListLine";

export default {
  name: "PopStatementHistory",
  components: {
    PopStatementHistoryListLine,
  },
  props: {
    dataList: Array,
  },
  data() {
    return {};
  },
};
</script>
