<template>
  <div class="connect_comm">
    <slot />
  </div>
</template>

<script>
export default {
  name: "Connect",
};
</script>

<style scoped>
.connect_comm {
  padding-bottom: 8px;
  margin: -8px 0;
}
.connect_comm:first-child {
  padding-top: 0;
}
.btn_small + .connect_edit,
.group_form + .connect_edit {
  margin-top: 0;
}

.list_connect {
}
.list_connect li {
  display: inline-block;
  position: relative;

  margin: 8px 8px 0 0;
  border-radius: 2px;
  background-color: #f6f6fc;
  vertical-align: top;
  box-sizing: border-box;
}
.list_connect li.disabled {
  background-color: #f9f9f9;
}

/* 링크 ico */
.list_connect .ico_connect_sm {
  position: absolute;
  top: 5px;
  left: 10px;
}

/* 이동버튼 */
.list_connect .btn_connect {
  display: block;
  width: 100%;
  padding: 7px 40px 7px 40px;
  text-align: left;
  outline: none;
}
.list_connect .tit_connect {
  display: block;
  overflow: hidden;
  font-size: 12px;
  line-height: 18px;
  color: #555;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-decoration: underline;
}
.list_connect > li > .tit_connect {
  padding: 7px 0 7px 40px;
  text-decoration: none;
}
/* 이동 ico */
.list_connect .ico_arr_connect {
  position: absolute;
  top: 11px;
  right: 16px;
}

/* 삭제버튼 */
.list_connect .btn_delete {
  position: absolute;
  top: 0;
  right: 0;
  outline: none;
}
.list_connect .btn_delete .ico_cross {
  margin: 7px 12px;
}

/* edit */
.connect_edit .list_connect li {
  padding-right: 42px;
}
.connect_edit .list_connect .btn_connect {
  width: auto;
  max-width: 100%;
}
</style>
