<template>
  <FileList :dataList="dataList" :isFileUploadCompleted="true" @onClickDownload="onClickDownload" />
</template>

<script>
import FileList from "./FileList";
import ApiService from "@/services/ApiService";
import { downloadFile } from "@/utils/fileUtils";

export default {
  name: "FileView",
  components: {
    FileList,
  },
  props: {
    dataList: Array,
    noticeSeq: Number,
  },
  methods: {
    async onClickDownload(item) {
      const isNoticeMode = this.noticeSeq === 0 || this.noticeSeq;

      let path;

      // 권한이 필요 없는 파일 다운로드가 생겼다.
      // 범용성 없이 메인 - 공고만 분기해서 처리한다.
      if (isNoticeMode) {
        path = `${this.$apiPath.ATTACH_FILE}/${item.sysFilePath}/${this.noticeSeq}/no`;
      } else {
        path = `${this.$apiPath.ATTACH_FILE}/${item.sysFilePath}`;
      }

      // const path = `${ this.$apiPath.ATTACH_FILE }/${ item.sysFilePath }`;

      const result = await ApiService.shared.getFile(path, null, !isNoticeMode);

      downloadFile(result, item.orgFile);
    },
  },
};
</script>
