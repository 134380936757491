<template>
  <div class="area_tbl">
    <div class="tbl_comm tbl_list">
      <table>
        <colgroup>
          <col style="width: 204px" />
          <!-- No -->
          <col style="width: 344px" />
          <!-- 기안금액 -->
          <col />
          <!-- 프로젝트코드 -->
        </colgroup>
        <thead>
          <tr>
            <th class="align_left">No.</th>
            <th>기안금액</th>
            <th>프로젝트코드</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in purchasePriceList" :key="index">
            <td class="align_left">
              {{ index + 1 }}
            </td>
            <td>{{ item.draftingPrice | currency }}</td>
            <td>{{ item.projectCode }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="tbl_foot">
      <div class="area_total">
        <dl>
          <dt>총 기안금액 <span class="txt_info">(부가세 제외)</span></dt>
          <dd>{{ totalDraftingPrice }}</dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DraftPurchasePrice",
  props: {
    purchasePriceList: Array,
    totalDraftingPrice: String,
  },
};
</script>
