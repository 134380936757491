<template>
  <tr>
    <td>{{ rowData.settlementCid }}</td>
    <td>{{ rowData.userName }}</td>
    <td>{{ rowData.price | currency }}</td>
  </tr>
</template>

<script>
export default {
  name: "PopStatementHistoryListLine",
  props: {
    rowData: Object,
  },
};
</script>
