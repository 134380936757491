<template>
  <div>
    <div class="head_section">
      <h2 id="dkBody" class="tit_subdesc">
        <span class="txt_number">요청번호 : {{ docNo }}</span>
        <span class="txt_info">{{ _.unescape(itemData.docTitle) }}</span>
      </h2>
      <div class="pos_right">
        <!-- <Radio
          name="radioPublic"
          title="문서 공개 여부"
          :dataList="DOC_PUBLIC_RADIO_LIST"
          :selectedId.sync="selectedRadioId"
        /> -->
      </div>
    </div>
    <!-- 결재현황 -->
    <div class="section_g">
      <PayStatus
        :approval-line="approvalLine"
        :doc-no="docNo"
        :doc-flag="'DI'"
        :isViewer="false"
        :viewerList.sync="viewerList"
        :dateTimeFormat="dateTimeFormat"
        @searchOrganization="searchOrganization"
        @popDeleteViewer="popDeleteViewer"
      />
    </div>
    <!-- 승인 반려 코멘트 -->
    <RejectComment v-if="whetherExistComment" :approval-line="approvalLine" />
    <!-- 구매요청 기본정보 -->
    <DraftReqeustInfo
      :draftData="itemData"
      :isCompleteStep="true"
      :isView="true"
      :statementTotalPrice="statementTotalPrice"
      :purchaseHistoryDataList="itemData.purchaseHistory"
      @onClickPopPriceAdd="onClickPopPriceAdd"
      @onClickPopPriceAddHistory="onClickPopPriceAddHistory"
      @onClickPopStatementHistory="onClickPopStatementHistory"
    />
    <!-- 구매요청품목 -->
    <!-- <DraftPurchaseGoodsList v-if="model.assetType === assetType.INFRA_ASSET" :draftData="model" /> -->
    <!-- 정산정보 -->
    <!-- <DraftPurchasePayment
      :draftData="model"
      :isCompleteStep="true"
      :isView="true"
      @onChangeAccountYnStatus="onChangeAccountYnStatus"
    /> -->
    <!-- PdfViewer -->
    <PdfViewer v-if="itemData.targetPk" :pdfInfoObj="itemData.pdfHistoryResult" />
    <!-- 첨부파일 -->
    <DraftFile :attachFileList.sync="model.attachFileList" />
    <div class="wrap_btn type_bottom">
      <div class="pos_left">
        <button type="button" class="btn_large btn_fourthly" @click.prevent="gotoList">목록</button>
      </div>
      <div class="pos_right">
        <button
          v-if="couldCancel"
          type="button"
          class="btn_large btn_secondary"
          @click.prevent="popUpCancelLayer"
        >
          상신 취소
        </button>
      </div>
    </div>
    <PopPriceAdd
      v-if="isPopPriceAdd"
      :priceAddTargetData="priceAddTargetData"
      @onClickClose="onClickClosePopPriceAdd"
      @onClickComplete="onClickCompletePopPriceAddBtn"
    />
    <PopPriceAddHistory
      v-if="isPopPriceHistory"
      :histories="priceAddHistories"
      @onClickClose="onClickClosePopPriceAddHistory"
    />
    <PopStatementHistory
      v-if="isPopStatementHistory"
      :dataList="model.purchaseHistory"
      @onClickClose="onClickClosePopStatementHistory"
    />
  </div>
</template>

<script>
import Radio from "@/_approval/components/common/radio/Radio";
import PayStatus from "@/_approval/components/shared/view/PayStatus";
import RejectComment from "@/_approval/components/shared/view/RejectComment";
import DraftReqeustInfo from "@/_approval/components/payment/view/DraftReqeustInfo";
// import DraftPurchaseGoodsList from '@/_approval/components/purchaseDraft/view/DraftPurchaseGoodsList';
// import DraftPurchasePayment from '@/_approval/components/purchaseDraft/view/DraftPurchasePayment';
import DraftFile from "@/_approval/components/purchaseDraft/view/DraftFile";
import PopPriceAdd from "@/_approval/components/purchaseDraft/view/popup/PopPriceAdd";
import PopPriceAddHistory from "@/_approval/components/purchaseDraft/view/popup/PopPriceAddHistory";
import PopStatementHistory from "@/_approval/components/purchaseDraft/view/popup/PopStatementHistory";
import PurchaseRequestDetailMixin from "@/_approval/components/shared/view/PurchaseRequestDetailMixin";
import commDocs from "@/_approval/mixins/common/commDocs";
// import DraftWriteModel from '@/_approval/pages/purchaseDraft/write/DraftWriteModel';
import DraftWriteModel from "@/_approval/pages/payment/write/DraftWriteModel";
import ApiService from "@/services/ApiService";

import {
  assetType,
  docStatus,
  targetGubun,
  aprvlLineType,
  approvalStatus,
} from "@/_approval/const/const.js";
import PdfViewer from "@/components/shared/PdfViewer";

export default {
  name: "DraftViewDetail",
  components: {
    Radio,
    PayStatus,
    RejectComment,
    DraftReqeustInfo,
    // DraftPurchaseGoodsList,
    // DraftPurchasePayment,
    DraftFile,
    PopPriceAdd,
    PopPriceAddHistory,
    PopStatementHistory,
    PdfViewer,
  },
  mixins: [PurchaseRequestDetailMixin, commDocs],
  data() {
    return {
      model: new DraftWriteModel(),
      approvalLine: [],
      priceAddHistories: [],
      assetType: assetType,
      statementTotalPrice: 0,
      docNo: this.$route.params.id,
      itemData: {},
      itemId: "",
      targetGubun: "",
      docStatus: Object.freeze(docStatus),
      docFlag: "DI",
    };
  },
  computed: {
    couldCancel: function () {
      const couldNotCancelStatus = [
        approvalStatus.APPROVAL_PAYMENT,
        approvalStatus.REJECT,
        approvalStatus.DRAFT_REJECT,
        approvalStatus.DRAFT_BEFORE_REJECT,
      ];

      return !this.approvalLine.some((e) => couldNotCancelStatus.includes(e.aprvlStatus));
    },
  },
  created() {
    this.getData();
  },
  mounted() {},
  methods: {
    async getData() {
      this.itemId = this.$route.params.id;
      const path = `${this.$apiPath.APRVL_DOC_LIST}/STD/${this.itemId}`;

      const result = await ApiService.shared.getData(path);
      const { code, data, text } = result;

      this.itemData = data;
      this.docNo = data.docNo;
      this.targetGubun = data.targetGubun;
      this.approvalLine = data.aprvlLineList;
      this.model = data;
      this.updateRead();
      return;
    },
    popUpCancelLayer() {
      this._showLayer({ type: "confirm", content: "신청을 취소하시겠습니까?" }, this.cancelDraft);
    },
    async cancelDraft() {
      const res = await ApiService.shared.put(this.$apiPath.APRVL_DOC_LIST, {
        docStatus: docStatus.TEMPORARY,
        targetGubun: targetGubun.STANDARD,
        docNo: this.docNo,
      });

      if (!this.checkNormalResponse(res)) {
        return;
      }
      this.gotoList();

      // await this.$router.push(this.$routerPath.PAYMENT_DRAFT_TEMP);
    },
    gotoList() {
      // this.$router.push(this.$routerPath.PURCHASE_REQUEST_APPROVAL_COMPLETE);
      this.$router.push({
        path: this.$routerPath.PAYMENT,
        // query: this.$route.query,
      });
    },
    async initData() {
      const data = await this.fetchStoredDraftData(this.docNo);

      this.model.initFromFetchData(data);
      this.model.deptPathName = await this.fetchDeptPathNameByDeptCode(data.drftDeptCode);
      this.approvalLine = data.aprvlLineList;

      this.model.purchaseHistory = await this.fetchPurchaseHistoryList(data.drftDeptCode);
    },
    async onChangeAccountYnStatus(changedValue) {
      const completed = await this.accountYnStatus(changedValue);
      if (completed) {
        await this.initData();
      }
    },
    async fetchPurchaseHistoryList() {
      const res = await ApiService.shared.get(
        `${this.$apiPath.SETTLEMENT_PURCHASEHISTORY}/${this.model.targetPk}`,
      );
      if (res.data) {
        this.statementHistory = res.data.history;
        this.statementTotalPrice = res.data.totalPrice;
      }
      return res.data.history;
    },
    async onClickPopPriceAddHistory(item) {
      const res = await ApiService.shared.get(`${this.$apiPath.DRAFT_PRICE}/${this.docNo}`);

      if (!this.checkNormalResponse(res)) {
        return;
      }

      this.priceAddHistories = _.isEmpty(res.data)
        ? []
        : res.data.filter((e) => e.projectCode === item.projectCode);
      this.isPopPriceHistory = true;
    },
    async onClickCompletePopPriceAddBtn(addPriceData) {
      if (await this.onClickCompletePopPriceAdd(addPriceData)) {
        await this.initData();
      }
    },

    async updateRead() {
      const { docStatus, aprvlStatus } = this.docFlagToCode(this.docFlag);

      const params = {
        targetGubun: targetGubun.STANDARD,
        docNoList: [this.itemData.docNo],
        readYn: true,
        docStatus,
        aprvlStatus,
      };

      const res = await ApiService.shared.put(this.$apiPath.APRVL_DOC_READ, params);
    },
  },
};
</script>
