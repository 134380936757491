<template>
  <div class="box_addition">
    <strong class="screen_out">열람자 목록</strong>
    <dl class="list_addition">
      <dt>열람자</dt>
      <dd>
        <div class="area_addition">
          <div v-for="viewer in items" :key="viewer.empNo" class="group_addition">
            <span class="txt_addition">
              {{ unescape(viewer.deptName) }} {{ viewer.accountId }} ({{ viewer.empName }})
            </span>
            <!-- {{ viewer.insertDatetime | moment(dateTimeFormat) }}-->
            <button
              v-if="isViewerEdit"
              type="button"
              class="link_del"
              @click="$emit('delete', viewer)"
            >
              <span class="ico_account">삭제</span>
            </button>
          </div>
        </div>
      </dd>
    </dl>
  </div>
</template>
<script>
import { unescape } from "lodash";
export default {
  name: "PayStatusViewer",
  props: {
    items: {
      // 리스트
      type: Array,
      default: () => [],
    },
    // 날짜형식
    dateTimeFormat: {
      type: String,
      default: "YYYY-MM-DD HH:mm",
    },
    // 열람자 수정가능 여부
    isViewerEdit: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    unescape,
  },
};
</script>
