<template>
  <div class="box_choice">
    <strong v-if="title" class="tit_choice">{{ title }}</strong>
    <RadioItem
      v-for="item in dataList"
      :id="item.id || item.code"
      :key="item.code"
      :value="item.code || item.id"
      :text="item.name"
      :name="name"
      :isDisabled="isDisabled || item.isDisabled"
      :selectedId.sync="selectedIdSync"
      @onChange="onChange"
    />
  </div>
</template>

<script>
import RadioItem from "./RadioItem";

export default {
  components: {
    RadioItem,
  },
  props: {
    dataList: Array,
    name: String,
    title: String,
    selectedId: null,
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    selectedIdSync: {
      get() {
        return this.selectedId;
      },
      set(value) {
        this.$emit("update:selectedId", value);
      },
    },
  },
  methods: {
    onChange(to, from) {
      this.$emit("onChange", to, from);
    },
  },
};
</script>
