<template>
  <div class="blank_asset5">
    <!-- <div class="box_g box_comment"> -->
    <div class="head_tbl">
      <div class="pos_left">
        <strong class="tit_text">승인 반려 코멘트</strong>
      </div>
    </div>
    <ul class="list_comment">
      <li v-for="approval in approvalLine" :key="approval.aprvlOrder">
        <template v-if="approval.reason != null && approval.reason !== ''">
          <div class="info_user">
            <em class="screen_out">사용자명</em>
            <div class="txt_name">{{ approval.accountId }} ({{ approval.empName }})</div>
            <em class="screen_out">부서명</em>
            <div class="txt_team">
              {{ approval.deptName }}
            </div>
          </div>
          <div class="cont_comment">
            <div class="inner_comment">
              <div class="info_date">
                {{ approval.modDate | strDateTimeSplit }}
              </div>
              <p
                class="desc_txt"
                v-html="approval.reason && approval.reason.replace(/(?:\r\n|\r|\n)/g, '<br />')"
              />
            </div>
          </div>
        </template>
      </li>
    </ul>
    <!-- </div> -->
  </div>
</template>

<script>
import { commConst } from "@/_approval/const/const.js";

export default {
  name: "RejectComment",
  props: {
    approvalLine: Array,
  },
  computed: {
    dateTimeFormat: function () {
      return commConst.DATETIME_FORMAT;
    },
  },
};
</script>
