import { default as CommLayerMixin } from "@/_approval/mixins/common/commLayer.js";
import { default as CommToastMixin } from "@/_approval/mixins/common/commToast.js";
import { mapState } from "vuex";
import axios from "axios";
import { isEmpty } from "lodash";
import ApiService from "@/services/ApiService";
import { commConst, approvalStatus, targetGubun, aprvlLineType } from "@/_approval/const/const";
import { stringify } from "query-string";
import commDocs from "@/_approval/mixins/common/commDocs";

const PurchaseRequestDetailMixin = {
  mixins: [CommLayerMixin, CommToastMixin, commDocs],
  data() {
    return {
      docOpenYn: "docOpenY",
      approvalLine: [],
      payAccountId: "",
      docNo: this.$route.query.docNo,
      draftFlag: "",
      // remark: '',
      // paymentList: [],
      // invoiceList: [],
      // rejectPaymentIdList: [],
      viewerList: [],
      memberList: [],
      addApproverList: [],
      spliceAddIndex: null,
      spliceAddIndexFromAll: null,
      layerAfterAlertMsg: "",
      // apprReason: '',
      // subject: '',
      // mileageFlag: false,
      // assetApply: {},
      draftData: {},
    };
  },
  watch: {
    layerAfterAlertMsg() {
      if (this.layerAfterAlertMsg != "") {
        this._showLayer({ type: "alert", content: this.layerAfterAlertMsg });
        this.layerAfterAlertMsg = "";
      }
    },
    approvalLine() {
      this.getViewerList();
      this.getAddApproverList();
    },
  },
  computed: {
    ...mapState("CommStore", {
      login: (state) => state.login,
    }),
    rejectYn: function () {
      let rejectYn = false;
      this.approvalLine.forEach((approval) => {
        if (approval.apprReason != null && approval.apprReason !== "") {
          // 승인/반려 코멘트가 있는 경우
          rejectYn = true;
        }
      });
      return rejectYn;
    },
    dateTimeFormat: function () {
      return commConst.DATETIME_FORMAT;
    },
    isApproval() {
      let isApproval = false;
      this.approvalLine.some((approval) => {
        if (approval.empNo === this.login.empNo) {
          isApproval = true;
          return true;
        }
      });
      return isApproval;
    },
    whetherExistComment() {
      return this.approvalLine.some(
        (e) => e.aprvlLineType === aprvlLineType.APPROVAL && !isEmpty(e.reason),
      );
    },
  },
  methods: {
    getApprovalLineList(targetFlag) {
      axios
        .post("/api/selectDocument", {
          docNo: this.docNo,
          targetFlag: targetFlag,
        })
        .then((res) => {
          this.approvalLine = res.data.approvalLine;
          this.subject = res.data.subject;
          this.payAccountId = res.data.payAccountId;

          this.getApply();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getApply() {
      axios
        .post("/api/selectApply", { docNo: this.docNo }) // this.$DraftBus._data.currentReDraftDocNo})
        .then((res) => {
          this.assetApply = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getViewerList() {
      this.viewerList = this.approvalLine.filter((e) => e.aprvlLineType === aprvlLineType.VIEWER);

      this.memberList = [];
      let memberList = this.memberList.concat(this.viewerList);
      memberList.forEach((viewer) => {
        viewer.typeMe = true;
      });
      this.memberList = memberList;
    },
    getAddApproverList() {
      const approvalList = this.approvalLine.filter(
        (approver) =>
          approver.aprvlLineType === aprvlLineType.DRAFT ||
          approver.aprvlLineType === aprvlLineType.APPROVAL,
      );

      this.memberList = [];
      let memberList = this.memberList.concat(approvalList);
      memberList.forEach((member, index) => {
        // 추가한 결재자가 아닌경우
        if (!member.isTemp) {
          // 기존 결재라인에 중복제거
          const memberOverlayList = memberList.filter((item) => item.empNo == member.empNo);
          if (memberOverlayList.length > 1) {
            memberList = memberList.filter(
              (item, index2) => item.empNo != member.empNo || index == index2,
            );
          }
          // 기존 결재자 타입구분
          member.typeMe = true;
        }
      });
      this.memberList = memberList;

      // 중간 추가의 경우 - memberList에서의 (뒤에서부터)index값
      const nextApprover = memberList.find(
        (item) => item.aprvlStatus === approvalStatus.BEFORE_PAYMENT && !item.isTemp,
      );
      const nextApproverIndex = memberList.indexOf(nextApprover);
      this.spliceAddIndex = nextApprover ? (memberList.length - nextApproverIndex) * -1 : null;

      // 중간 추가의 경우 - approvalLine에서의 (뒤에서부터)index값
      const nextApproverFromAll = this.approvalLine.find(
        (item) => item.aprvlStatus === approvalStatus.BEFORE_PAYMENT && !item.isTemp,
      );
      const nextApproverIndexFromAll = this.approvalLine.indexOf(nextApproverFromAll);
      this.spliceAddIndexFromAll = nextApproverFromAll
        ? (this.approvalLine.length - nextApproverIndexFromAll) * -1
        : null;
    },
    popDeleteViewer: function (viewer) {
      this.deletedViewer = viewer;
      this._showLayer({ type: "confirm", content: "열람자 취소하시겠습니까?" }, this.deleteViewer);
    },
    popDeleteapprover: function (approver) {
      this.addApproverList = this.addApproverList.filter((item) => item.empNo != approver.empNo);
      this.memberList = this.memberList.filter((member) => member.typeMe);
      this.memberList = this.memberList.concat(this.addApproverList);
      this.approvalLine = this.approvalLine.filter((approver) => !approver.isTemp);
      this.addApproverList.forEach((addItem) => {
        addItem.aprvlLineType = aprvlLineType.APPROVAL;
        addItem.aprvlStatus = approvalStatus.BEFORE_PAYMENT;
        // 중간 추가가 아닌경우
        if (this.spliceAddIndexFromAll === null) {
          this.approvalLine.push(addItem);
          // 중간 추가의 경우
        } else {
          this.approvalLine.splice(this.spliceAddIndexFromAll, 0, addItem);
        }
      });
    },
    async deleteViewer() {
      const res = await ApiService.shared.delete(
        `${this.$apiPath.APRVL_LINE}/${targetGubun.DF_PURCHASE_REQUEST_WF}/${this.docNo}/${this.deletedViewer.empNo}`,
      );

      if (this.checkNormalResponse(res)) {
        const deletedIndex = this.approvalLine.findIndex(
          (e) => e.aprvlLineType === aprvlLineType.VIEWER && e.empNo === this.deletedViewer.empNo,
        );
        if (deletedIndex > -1) {
          this.approvalLine.splice(deletedIndex, 1);
        }
        this._showToast({ content: "열람자 취소 되었습니다." });
      }
    },
    searchOrganization(gubun) {
      let layer = {};
      layer.type = "popup";
      layer.class = "inner_structure_layer";
      layer.header = "조직도";
      layer.componentFile = "common/CommPopOrganization.vue";
      layer.props = {
        orgType: 2, // 1:single select, 2: multi select
        alertMsg: gubun === "viewer" ? "열람자를 선택해주세요." : "결재자를 선택해주세요.",
        spliceAddIndex: this.spliceAddIndex, // 중간추가시 추가할 항목의 뒤에서 부터 Index값
        employeeList: this.memberList,
      };
      // 열람자
      if (gubun === "viewer") {
        this._showLayer(layer, this.attachReferenceViewer);
        // 결재자
      } else {
        this._showLayer(layer, this.attachReferenceApprover);
      }
    },

    async attachReferenceViewer(employeeList) {
      if (isEmpty(employeeList)) {
        return;
      }
      let errorMsg = "";

      employeeList = employeeList.filter((item) => !item.typeMe);
      if (employeeList.length > 0) {
        for (const e of employeeList) {
          const res = await ApiService.shared.post(this.$apiPath.APRVL_LINE, {
            empNo: e.empNo,
            aprvlStatus: approvalStatus.APPROVAL_PAYMENT,
            aprvlLineType: aprvlLineType.VIEWER,
            targetGubun: targetGubun.DF_PURCHASE_REQUEST_WF,
            targetPkList: [this.docNo],
          });
          if (parseInt(res.code) !== 200) {
            this.errorMsg = res.text || "열람자 추가 실패했습니다. 관리자에게 문의하세요";
          }
          this.approvalLine.push(Object.assign(e, { aprvlLineType: aprvlLineType.VIEWER }));
        }
        this.memberList = employeeList;
        this._showToast({ content: "열람자 추가 되었습니다" });
      }

      // 에러발생시
      if (errorMsg != "") {
        this.layerAfterAlertMsg = errorMsg;
      }
    },
    attachReferenceApprover(employeeList) {
      if (isEmpty(employeeList)) {
        return;
      }

      const addEmployeeList = employeeList.filter((item) => !item.typeMe);

      // 추가결재자에 변경이 있을 경우 확인
      this.addApproverList.forEach((orginItem) => {
        // 추가결재자 중에 이번에 추가한 구성원과 다른 구성원이 있을 경우
        addEmployeeList.forEach((addItem) => {
          const isOverlay = addItem.empNo == orginItem.empNo;
          if (!isOverlay) {
            console.log(orginItem.accountId);
          }
        });
      });
      // 추가결재자만 추가결재자 적용
      this.addApproverList = addEmployeeList;
      // 조직도팝업은 전체 결재라인 결재자 적용
      this.memberList = employeeList;
      this.approvalLine = this.approvalLine.filter((approver) => !approver.isTemp);

      addEmployeeList.forEach((addItem) => {
        addItem.aprvlLineType = aprvlLineType.APPROVAL;
        addItem.aprvlStatus = approvalStatus.BEFORE_PAYMENT;
        addItem.isTemp = true;
        // 중간 추가가 아닌경우
        if (this.spliceAddIndexFromAll == null) {
          this.approvalLine.push(addItem);
          // 중간 추가의 경우
        } else {
          this.approvalLine.splice(this.spliceAddIndexFromAll, 0, addItem);
        }
      });

      // 변경사항 있을때만 토스트
      if (addEmployeeList.length > 0) {
        this._showToast({
          content: "등록된 추가 결재자는 승인 시 적용됩니다.",
        });
      }
    },
    // 금액추가 팝업 열기
    onClickPopPriceAdd(targetData) {
      this.priceAddTargetData = targetData;
      this.isPopPriceAdd = true;
    },
    // 금액추가 팝업 닫기
    onClickClosePopPriceAdd() {
      this.isPopPriceAdd = false;
      this.priceAddTargetData = {};
    },
    async onClickCompletePopPriceAdd(addPriceData) {
      const res = await ApiService.shared.post(this.$apiPath.DRAFT_PRICE, {
        addPrice: addPriceData.addPrice,
        comment: addPriceData.comment,
        projectCode: addPriceData.projectCode,
        requestCid: this.docNo,
      });

      this.isPopPriceAdd = false;
      this.priceAddTargetData = {};

      return this.checkNormalResponse(res);
    },
    // 추가금액 내역보기 팝업 열기
    async onClickPopPriceAddHistory(item) {
      const res = await ApiService.shared.get(`${this.$apiPath.DRAFT_PRICE}/${this.docNo}`);

      if (!this.checkNormalResponse(res)) {
        return;
      }

      this.priceAddHistories = isEmpty(res.data)
        ? []
        : res.data.filter((e) => e.projectCode === item.projectCode);
      this.isPopPriceHistory = true;
    },
    // 추가금액 내역보기 팝업 닫기
    onClickClosePopPriceAddHistory() {
      this.isPopPriceHistory = false;
    },
    // 정산서 내역보기 팝업 열기
    onClickPopStatementHistory() {
      this.isPopStatementHistory = true;
    },
    // 정산서 내역보기 팝업 닫기
    onClickClosePopStatementHistory() {
      this.isPopStatementHistory = false;
    },
    // 정산 상태 변경
    async accountYnStatus(changedValue) {
      const accountYn = changedValue; // ? 'Y' : 'N';
      const url = `${this.$apiPath.DRAFT_ACCOUNT}/${this.docNo}?${stringify({ accountYn })}`;
      const res = await ApiService.shared.put(url);

      return this.checkNormalResponse(res);
    },
    async updateRead() {
      console.log(this.docFlag);
      const { docStatus, aprvlStatus } = this.docFlagToCode(this.docFlag);

      const params = {
        targetGubun: targetGubun.DF_PURCHASE_REQUEST_WF,
        targetPkList: [this.docNo],
        readYn: true,
        docStatus,
        aprvlStatus,
      };

      // console.log('params', params);

      const res = await ApiService.shared.put(this.$apiPath.APRVL_DOC_READ, params);
    },
    docFlagToCode(flag) {
      // - 진행중 문서 : docStatus 'I'
      // - 완료된 문서 : docStatus 'C'
      // - 반려된 문서 : docStatus 'J'
      //
      // - 결재전 문서 : aprvlStatus 'W'
      // - 수신된 문서 : aprvlStatus 'R'
      // - 진행중 문서 : aprvlStatus 'A'
      // - 완료된 문서 : docStatus 'C', aprvlStatus 'ALL'
      // - 반려된 문서 : docStatus 'J', aprvlStatus 'ALL'
      //
      // - 진행중 문서 : docStatus 'I', aprvlStatus 'N'
      // - 처리된 문서 : docStatus 'C', aprvlStatus 'N'
      switch (flag) {
        case "DI":
          return { docStatus: "I", aprvlStatus: "" };
        case "DC":
          return { docStatus: "C", aprvlStatus: "" };
        case "DR":
          return { docStatus: "J", aprvlStatus: "" };
        case "AB":
          return { docStatus: "", aprvlStatus: "W" };
        case "AE":
          return { docStatus: "", aprvlStatus: "R" };
        case "AEW":
          return { docStatus: "", aprvlStatus: "R" };
        case "AI":
          return { docStatus: "", aprvlStatus: "A" };
        case "AC":
          return { docStatus: "C", aprvlStatus: "ALL" };
        case "AR":
          return { docStatus: "J", aprvlStatus: "ALL" };
        case "RI":
          return { docStatus: "I", aprvlStatus: "N" };
        case "RC":
          return { docStatus: "C", aprvlStatus: "N" };
      }
    },
  },
};

export default PurchaseRequestDetailMixin;
