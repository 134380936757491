<template>
  <span class="btn_delete-wrap">
    <button type="button" class="btn_delete" @click="onClickAlertRemove">
      <span class="ico_purchase ico_cross">삭제</span>
    </button>
    <button class="file_uploaded" @click="onClickDownload">
      <span :class="['ico_purchase', getClassByFileType]">{{ itemData.attachType }}</span>
      <span class="txt_file">{{ itemData.orgFile }}</span>
    </button>
  </span>
</template>

<script>
import ApiService from "@/services/ApiService";
import { ALERT_ACTION } from "@/store/modules/alert/action";
import { downloadFile } from "@/utils/fileUtils";

export default {
  name: "FileWriteItem",
  props: {
    itemData: Object,
  },
  computed: {
    getClassByFileType() {
      const fileExt = this.itemData.attachExt;

      if (!fileExt) return "ico_etc";

      switch (fileExt.toLowerCase()) {
        case "pdf":
          return "ico_pdf";
        case "png":
          return "ico_png";
        case "jpg":
          return "ico_jpg";
        case "jpeg":
          return "ico_jpg";
        case "zip":
          return "ico_zip";
        default:
          return "ico_etc";
      }
    },
  },
  methods: {
    onClickAlertRemove() {
      const params = {
        text: "첨부파일을 삭제하시겠습니까?",
        onClickY: this.onClickRemove,
      };

      this.$store.dispatch(ALERT_ACTION.SHOW_ALERT_YN, params);
    },
    onClickRemove() {
      this.$emit("onClickRemove", this.itemData);
    },
    async onClickDownload() {
      const path = `${this.$apiPath.ATTACH_FILE}/${this.itemData.sysFilePath}`;

      // const path = `${ this.$apiPath.ATTACH_FILE }/${ item.sysFilePath }`;

      const result = await ApiService.shared.getFile(path, null);

      downloadFile(result, this.itemData.orgFile);
    },
  },
};
</script>

<style scoped>
.file_uploaded .txt_file {
  color: #5551ce;
  text-decoration: underline;
}
</style>
