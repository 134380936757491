import LocalStorageManager from "@/LocalStorageManager";
import { getMoneyToNumber, addCommaForMoney } from "@/utils/stringUtils";
import ApiService from "@/services/ApiService";
import apiPath from "@/services/apiPath";
import { padLeft } from "@/utils/numberUtils";
import {
  accountType,
  aprvlLineType,
  assetType,
  docStatus,
  targetGubun,
} from "@/_approval/const/const";
import ApprovalLineModel from "@/_approval/pages/payment/write/ApprovalLineModel";
import store from "@/store";

export default class DraftWriteModel {
  constructor() {
    this.title = ""; // 구매요청명
    this.itemTitle = ""; // 구매 카테고리
    this.contents = ""; // 상세내용

    this.attachFileList = []; // 첨부파일

    /**
     * PK
     * @type {string}
     * @private
     */
    this._targetPk = "";
    /**
     * 문서 상태
     * @type {docStatus}
     * @private
     */
    this._docStatus = null;
    /**
     * 구매요청부서
     * @type {string}
     * @private
     */
    this._deptPathName = "";
    /**
     * 구매요청 부서코드
     * @type {string}
     * @private
     */
    this._deptCode = "";
    /**
     * 구매요청 부서명
     * @type {string}
     * @private
     */
    this._deptName = "";
    /**
     * 결제선 지정 - 계정선택
     * name: 계정명, code: 계정아이디
     * @type {*[]}
     * @private
     */
    this._approvalLineAccounts = null;

    /**
     * 발제 부서라인
     * @type {*[]}
     * @private
     */
    this._draftDeptApprLine = [];
    /**
     * 승인자 처리라인
     * @type {*[]}
     * @private
     */
    this._processingApprovalLine = [];
    /**
     * 참조자
     * @type {*[]}
     * @private
     */
    this._referenceApprovalLine = [];
    /**
     * 나의 발제 부서 정보
     * 발제 부서 라인에  나의 정보는 빠져있으므로 생성시 같이 사용함.
     * @type {ApprovalLineModel}
     * @private
     */
    this._myDraftDeptInfo = null;
    /**
     * 이메일
     * @type {string}
     * @private
     */
    this._email = "";
    /**
     * 모바일 번호
     * @type {string}
     * @private
     */
    this._mobile = "";
    /**
     * 등록일
     * @type {string}
     * @private
     */
    this._regDate = "";
    /**
     * 기안자명
     * @type {string}
     * @private
     */
    this._drftEmpName = "";
    /**
     * 공개 여부
     * @type {boolean}
     * @private
     */
    this._publicYn = false;
    /**
     * 반려 전 기존 상신된 문서 PK
     * @type {string}
     * @private
     */
    this._reDrftOriDocNo = "";
  }

  resetData() {}

  setData(obj) {
    this.objRaw = obj;

    const { docTitle, contents, attachFileList, drftEmpName, drftDeptName, drftDeptCode } = obj;

    this.title = `${_.unescape(docTitle)}` || "";

    this.contents = contents || "";

    this.attachFileList = attachFileList || [];

    this._drftEmpName = drftEmpName || "";
    this._deptCode = drftDeptCode || "";
    this._deptName = drftDeptName || "";
  }

  getData() {
    let obj = {
      title: this.title,

      contents: this.contents,
    };

    if (this.attachFileList) {
      obj.attachFileList = this.attachFileList.map((item) => item.getData());
    }

    return Object.assign({}, this.objRaw, obj);
  }

  /**
   * 유효성 검사 체크
   * - 발제 부서 라인의 경우 필수이지만 기본으로 자신이 포함되어 있으므로 유효성 검새에서 제외한다.
   * @return {string|undefined}
   */
  validate(isTemp) {
    // if (!this._approvalLineAccounts) {
    //   return '결제선 지정의 계정을 선택해주세요.';
    // }
    if (!this._deptCode) {
      return "신청자 부서를 입력해주세요.";
    }
    if (!this._processingApprovalLine || this._processingApprovalLine.length === 0) {
      return "승인자 처리 라인을 선택해주세요.";
    }
    if (!this.title) {
      return "기안명을 입력해주세요.";
    }

    return null;
  }

  makeFormData() {
    return {
      docStatus: this._docStatus,
      aprvlLineAccntNo: this._approvalLineAccounts ? this._approvalLineAccounts.code : null,
      reDrftOriDocNo: this._reDrftOriDocNo,
      targetGubun: targetGubun.STANDARD, //임스 테스트 구분값
      publicYn: false,
      aprvlLineList: this._makeAprvlLineList(),
      data: this._makeDraftDetailData(this._docStatus === docStatus.TEMPORARY),
      attachFileList: this.attachFileList,
    };
  }

  _makeDraftDetailData(isTemp = false) {
    return {
      title: this.title,
      contents: this.contents,
      // td1: this.contents, //테스트 데이터
      // td2: this.contents, //테스트 데이터
      //deptCode: this._deptCode,
      //deptName: this._deptName,
      //deptPathName: this._deptPathName,
      //email: this._email,
      // isTemp: isTemp === true ? 'Y' : 'N',
      //mobile: this._mobile,
    };
  }

  _makeAprvlLineList() {
    const approvalLineList = [];
    approvalLineList.push(...this._makeDraftDeptApprovalLineList());
    approvalLineList.push(...this._makeProcessingApprovalLine());
    approvalLineList.push(...this._makeReferenceApprovalLine());

    return approvalLineList.map((e, index) => e.toJson(index + 1));
  }

  _makeReferenceApprovalLine() {
    if (!this._referenceApprovalLine || this._referenceApprovalLine.length === 0) {
      return [];
    }

    return this._referenceApprovalLine.map(
      (e) => new ApprovalLineModel(e.empNo, e.deptCode, aprvlLineType.REFERENCE),
    );
  }

  _makeProcessingApprovalLine() {
    return this._processingApprovalLine.map(
      (e) => new ApprovalLineModel(e.empNo, e.deptCode, aprvlLineType.APPROVAL),
    );
  }

  _makeDraftDeptApprovalLineList() {
    const approvalLine = [];
    if (this._myDraftDeptInfo) {
      approvalLine.push(this._myDraftDeptInfo);
    }

    if (this._draftDeptApprLine && this._draftDeptApprLine.length > 0) {
      const otherLine = this._draftDeptApprLine.map(
        (e) => new ApprovalLineModel(e.empNo, e.deptCode, aprvlLineType.APPROVAL, "", true),
      );

      approvalLine.push(...otherLine);
    }

    return approvalLine;
  }

  initFromFetchData(data) {
    const detailData = data.data;

    this._approvalLineAccounts = { code: data.aprvlLineAccntNo };
    this._targetPk = data.targetPk;
    this._regDate = data.regDate;
    this._drftEmpName = data.drftEmpName;
    this._deptName = data.drftDeptName;
    this._publicYn = data.publicYn;

    this._reDrftOriDocNo = detailData.reDrftOriDocNo;
    this._deptCode = detailData.deptCode;
    this._deptPathName = detailData.deptPathName;
    this._email = detailData.email;
    this._mobile = detailData.mobile;
    this.title = detailData.title;

    this.contents = detailData.contents;
    this.accountYn = detailData.accountYn;

    if (detailData.attachFileList) {
      this.attachFileList = detailData.attachFileList;
    }
  }

  set deptPathName(depthPathName) {
    this._deptPathName = depthPathName;
  }
  set deptCode(deptCode) {
    this._deptCode = deptCode;
  }
  set draftDeptApprLine(draftDeptApprLine) {
    this._draftDeptApprLine = draftDeptApprLine;
  }
  set processingApprovalLine(value) {
    this._processingApprovalLine = value;
  }
  set referenceApprovalLine(value) {
    this._referenceApprovalLine = value;
  }
  set approvalLineAccounts(value) {
    this._approvalLineAccounts = value;
  }
  set docStatus(value) {
    this._docStatus = value;
  }
  set myDraftDeptInfo(value) {
    this._myDraftDeptInfo = value;
  }
  set deptName(value) {
    this._deptName = value;
  }
  set email(value) {
    this._email = value;
  }
  set mobile(value) {
    this._mobile = value;
  }
  set targetPk(value) {
    this._targetPk = value;
  }
  set regDate(value) {
    this._regDate = value;
  }
  set reDrftOriDocNo(value) {
    this._reDrftOriDocNo = value;
  }

  get deptPathName() {
    return this._deptPathName;
  }
  get targetPk() {
    return this._targetPk ? this._targetPk : "-";
  }
  get regDate() {
    return this._regDate;
  }
  get drftEmpName() {
    return this._drftEmpName;
  }
  get publicYnAsStr() {
    return this._publicYn === true ? "Y" : "N";
  }
}
