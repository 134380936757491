<template>
  <div :class="['file_view', { file_uploaded: isFileUploadCompleted }]">
    <template v-if="isFileUploadCompleted">
      <FileViewItem
        v-for="(item, index) in dataList"
        :key="index"
        :itemData="item"
        @onClickDownload="onClickDownload"
      />
    </template>
    <template v-else>
      <FileWriteItem
        v-for="(item, index) in dataList"
        :key="index"
        :itemData="item"
        @onClickRemove="onClickRemove"
      />
    </template>
  </div>
</template>

<script>
import FileViewItem from "./FileViewItem";
import FileWriteItem from "./FileWriteItem";

export default {
  name: "FileList",
  components: {
    FileViewItem,
    FileWriteItem,
  },
  props: {
    dataList: Array,
    isFileUploadCompleted: Boolean,
  },
  methods: {
    onClickRemove(item) {
      this.$emit("onClickRemove", item);
    },
    onClickDownload(item) {
      this.$emit("onClickDownload", item);
    },
  },
};
</script>

<style scoped>
.file_view {
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
  height: 48px;
  margin-top: 8px;
  padding: 0 24px 8px 4px;
}
.file_view /deep/ .item_file {
  font-size: 0;
}
.file_view /deep/ .btn_file {
  display: block;
  padding-left: 25px;
  line-height: 18px;
  outline: none;
  text-indent: -25px;
  text-align: left;
}
.file_view /deep/ .btn_file + .btn_file {
  margin-top: 8px;
}
.file_view /deep/ .btn_delete {
  display: block;
  padding-left: 50px;
  outline: none;
  text-indent: -50px;
  text-align: left;
}
.file_view /deep/ .btn_delete + .btn_delete {
  margin-top: 4px;
}
.file_view /deep/ .btn_delete-wrap {
  position: relative;
  display: block;
  padding-left: 25px;
}
.file_view /deep/ .btn_delete-wrap .btn_delete {
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  vertical-align: middle;
  padding-left: 20px;
  text-indent: -20px;
}

.file_view /deep/ .btn_delete-wrap .file_uploaded {
  display: inline-block;
  text-align: left;
}
.file_view /deep/ .ico_cross {
  margin-right: 7px;
}

.file_view /deep/ .ico_pdf {
  margin-right: 7px;
}
.file_view /deep/ .ico_png {
  margin-right: 7px;
}
.file_view /deep/ .ico_jpg {
  margin-right: 7px;
}
.file_view /deep/ .ico_zip {
  margin-right: 7px;
}
.file_view /deep/ .ico_etc {
  margin-right: 7px;
}

.file_view /deep/ .txt_file {
  font-size: 12px;
  line-height: 18px;
  vertical-align: top;
  word-wrap: break-word;
  word-break: break-all;
}
.file_uploaded {
  overflow: inherit;
  height: inherit;
  margin: 0;
  padding: 7px 0 8px;
}
.file_uploaded /deep/ .txt_file {
  color: #5551ce;
  text-decoration: underline;
}

.file_ellip {
  height: auto;
}
.file_ellip /deep/ .btn_file {
  display: block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
