export default class ApprovalLineModel {
  /**
   * 결제선 MODEL
   * @param empNo 사원 번호
   * @param deptCode 부서 코드
   * @param aprvlLineType 결제라인 타입
   * @param reason 사유
   * @param prsntDeptYn 발제 부서 여부
   */
  constructor(empNo, deptCode, aprvlLineType, reason = "", prsntDeptYn = false) {
    this._empNo = empNo;
    this._aprvlLineType = aprvlLineType;
    this._deptCode = deptCode;
    this._reason = reason;
    this._prsntDeptYn = prsntDeptYn;
  }

  toJson(order) {
    return {
      empNo: this._empNo,
      aprvlLineType: this._aprvlLineType,
      prsntDeptYn: this._prsntDeptYn,
      aprvlOrder: order,
      reason: this._reason,
      deptCode: this._deptCode,
    };
  }
}
