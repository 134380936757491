<template>
  <div :class="['area_fold', { folded: isFoldedData }]">
    <TableHead>
      <template v-slot:headLeft>
        <button type="button" class="btn_fold" @click="onClickFold">
          {{ title }}
          <span class="icon_fold"><span class="ico_purchase ico_arr_fold_gray" /></span>

          <span v-if="isEssential" class="ico_purchase ico_star">필수항목</span>
        </button>
      </template>
      <template v-slot:headRight>
        <slot name="headRight" />
      </template>
    </TableHead>
    <div v-show="!isFoldedData" class="fold_body">
      <slot />
    </div>
    <div class="bar_fold" @click="onClickFold">
      <span class="ico_purchase ico_arr_fold2_gray" />
    </div>
  </div>
</template>

<script>
import TableHead from "@/components/shared/TableHead";
export default {
  name: "Fold",
  components: {
    TableHead,
  },
  props: {
    title: String,
    isFolded: {
      type: Boolean,
      default: false,
    },
    isEssential: true,
  },
  data() {
    return {
      isFoldedData: this.isFolded,
    };
  },
  methods: {
    onClickFold(e) {
      if (this.isFoldedData) {
        this.isFoldedData = false;
      } else {
        this.isFoldedData = true;
      }
    },
  },
};
</script>
<style scoped>
.area_fold {
  position: relative;
  padding-bottom: 15px;
}
.area_fold .btn_fold {
  display: block;
  font-size: 14px;
  line-height: 20px;
  color: #888;
  outline: none;
}
/* .area_fold .btn_fold:hover{color:#4871ED} */
.area_fold .btn_fold .icon_fold {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 3px 0 4px 1px;
  padding: 6px 4px 5px;
  border-radius: 2px;
  background-color: #f5f5f5;
  box-sizing: border-box;
  vertical-align: top;
}
.area_fold .btn_fold:hover .icon_fold {
  background-color: #f0f0f0;
}
.area_fold .btn_fold .ico_fold_txt {
  margin: 7px 9px;
}
.bar_fold {
  position: absolute;
  z-index: 10;
  bottom: 0;
  left: 0;
  right: 0;
  height: 6px;
  padding: 5px 0 5px;
  border-top: 1px solid #ccc;
  background-color: #f5f5f5;
  text-align: center;
  cursor: pointer;
}
.bar_fold:hover {
  background-color: #f0f0f0;
}

.area_fold.folded {
  padding-bottom: 17px;
}

.area_view + .area_fold,
.area_fold + .area_fold,
.tab_comm + .area_fold {
  margin-top: 10px;
}
</style>
