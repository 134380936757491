<template>
  <div>
    <template v-if="!computedNotPdfView">
      <div class="blank_asset5">
        <div class="head_tbl">
          <div class="pos_left">
            <strong class="tit_text">PDF 정보</strong>
          </div>
        </div>
        <div class="tbl_comm tbl_view">
          <table>
            <caption class="ir_caption">
              첨부파일 표
            </caption>
            <colgroup>
              <col style="width: 192px" />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">PDF파일 연결문서</th>
                <td>
                  <ConnectView
                    :dataList="[...computedPdfConnetData]"
                    :disabledData="{ aprvlStatus: '-' }"
                    @onClickConnect="onClickConnect"
                  />
                </td>
              </tr>
              <tr>
                <th scope="row">PDF파일 다운로드</th>
                <td>
                  <FileList
                    :dataList="[computedPdfInfoObj]"
                    :isFileUploadCompleted="true"
                    @onClickDownload="down"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="blank_asset5">
        <div class="head_tbl">
          <div class="pos_left">
            <strong class="tit_text">연결문서 정보</strong>
          </div>
        </div>
        <div class="tbl_comm tbl_view">
          <table>
            <caption class="ir_caption">
              첨부파일 표
            </caption>
            <colgroup>
              <col style="width: 192px" />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">연결문서</th>
                <td>
                  <ConnectView
                    :dataList="[...computedPdfConnetData]"
                    :disabledData="{ aprvlStatus: '-' }"
                    @onClickConnect="onClickConnect"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
    <template v-if="!computedNotPdfView">
      <template v-if="!isPDFError">
        <Fold title="PDF 미리보기">
          <div class="tbl_comm" style="max-width: 1240px; margin: 0 auto">
            <Pdf v-for="i in numPages" :key="i" :page="i" :src="PDFUrl" />
          </div>
        </Fold>
      </template>
      <template v-else>
        <div class="pdfviewer-error">
          PDF 정보를 읽을수 없습니다.<br />
          <button class="pdfviewer-error__btn" @click="onClickPdfGenerator">
            PDF 재생성 <span class="icon-realod material-icons"> sync </span>
          </button>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import Pdf from "vue-pdf";
import ApiService from "@/services/ApiService";
import Fold from "@/components/common/fold/Fold";
import FileView from "@/components/common/file/FileView";
import FileList from "@/components/common/file/FileList";
import ConnectView from "@/components/common/connect/ConnectView";
import { downloadPdf, ObjectURLPdf } from "@/utils/pdfUtils";
import { ALERT_ACTION } from "@/store/modules/alert/action";
export default {
  name: "PdfViewer",
  components: {
    Fold,
    Pdf,
    FileView,
    FileList,
    ConnectView,
  },
  props: {
    pdfInfoObj: {
      type: Object,
    },
  },
  data() {
    return {
      dataReqPK: "",

      PDFUrl: "",
      numPages: undefined,
      PDFSysFilePath: "",
      guide_open: "",
      // itemData: {},
      downloadDataArr: [],

      isPDFError: false,
    };
  },
  computed: {
    computedNotPdfView() {
      const { menuId, reqTitle, reqPath, refPk } = this.pdfInfoObj;
      let ret = false;
      if (menuId === "TAX" || menuId === "info/partner") {
        ret = true;
      }
      return ret;
    },
    computedPdfInfoObj() {
      const ret = {
        attachExt: "pdf",
        orgFile: `${_.unescape(this.pdfInfoObj.reqTitle)}.pdf`,
        ...this.pdfInfoObj,
      };
      return ret;
    },
    computedPdfConnetData() {
      const { menuId, reqTitle, reqPath, refPk } = this.pdfInfoObj;
      const refPkArray = refPk.split(",");
      const reduceArray = refPkArray.reduce((acc, item) => {
        const obj = {
          title: _.unescape(item),
          path: `${this.$routerPath.TRANSACTIONREPORT_VIEW}/${item}`,
          requestCid: item,
        };
        acc.push(obj);
        return acc;
      }, []);

      if (menuId === "TAX") {
        return [...reduceArray];
      } else if (menuId === "info/partner") {
        return [{ title: _.unescape(reqTitle), path: `${reqPath}`, requestCid: refPk }];
      } else {
        return [
          {
            title: _.unescape(reqTitle),
            path: reqPath,
            requestCid: reqPath,
          },
        ];
      }
    },
  },
  mounted() {},
  created() {
    // this.dataReqPK = this.reqPK;
    this.getData();
  },
  methods: {
    alert(strMess, cb) {
      if (!strMess) {
        return;
      }
      this.$store.dispatch(ALERT_ACTION.SHOW_ALERT, {
        text: strMess,
        onClickY: cb,
      });
    },

    async getData() {
      this.PDFSysFilePath = this.pdfInfoObj.sysFilePath;
      const arrayBuffer = await ApiService.shared.getFile(
        `${this.$apiPath.PDF_HISTORY}/${this.PDFSysFilePath}`,
      );
      // const byteArray = new Uint8Array(arrayBuffer);
      // const blob = new Blob([byteArray], { type: 'application/pdf' });
      this.PDFUrl = ObjectURLPdf(arrayBuffer); //URL.createObjectURL(blob);
      this.countNumPages();
    },
    countNumPages() {
      let PDFData = Pdf.createLoadingTask(this.PDFUrl);

      PDFData.promise.then(
        (Pdf) => {
          this.numPages = Pdf.numPages;
        },
        (reason) => {
          this.isPDFError = true;
        },
      );
    },

    toggleGuide() {
      if (this.guide_open === "") {
        this.guide_open = "guide_open";
      } else {
        this.guide_open = "";
      }
    },
    async down() {
      const result = await ApiService.shared.getFile(
        `${this.$apiPath.PDF_HISTORY}/${this.PDFSysFilePath}`,
      );

      downloadPdf(result, this.pdfInfoObj.reqTitle);
    },
    onClickConnect(data) {
      this.$windowOpen(data.path);
    },
    onClickPdfGenerator() {
      this.pdfGenerator();
    },
    async pdfGenerator() {
      let path = "";
      if (this.pdfInfoObj.menuId === "contract") {
        path = `${this.$apiPath.CONTRACT_PDF_GENERATOR}/${this.pdfInfoObj.refPk}`;
      } else if (this.pdfInfoObj.menuId === "order") {
        //order
        path = `${this.$apiPath.ORDER_PDF_GENERATOR}/${this.pdfInfoObj.refPk}`;
      } else {
        this.alert("오류가 발생하였습니다.<br />관리자에게 문의부탁드립니다.");
        return;
      }
      const result = await ApiService.shared.putData(path);
      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(text);
        return;
      }
      window.location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .file_uploaded .ico_pdf {
    background-position: 0 -180px;
  }
  .pdfviewer-error {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    padding: 25px 0;
    font-size: 24px;
    &__btn {
      margin-top: 15px;
      font-size: 18px;
      & > .icon-realod {
        vertical-align: -4px;
      }
    }
  }
}
</style>
