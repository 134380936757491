<template>
  <div class="blank_asset5">
    <div class="head_tbl">
      <div class="pos_left">
        <strong class="tit_text type_outside">기본정보</strong>
      </div>
      <div class="pos_right">
        <a v-if="draftData.agitId" :href="agitPath" target="_blank" class="btn_medium btn_secondary"
          >아지트 링크</a
        >
      </div>
    </div>
    <div class="tbl_comm tbl_view">
      <table>
        <caption class="ir_caption">
          기본정보 표
        </caption>
        <colgroup>
          <col style="width: 192px" />
          <col />
          <col style="width: 192px" />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <th>제목</th>
            <td colspan="3" v-html="draftData.docTitle"></td>
          </tr>

          <tr>
            <th scope="row">상세내용</th>
            <td colspan="3" v-html="handleNewLine(contents)" />
          </tr>
        </tbody>
      </table>
    </div>
    <!-- <DraftPurchasePrice
      v-if="!isCompleteStep"
      :assetType="draftData.assetType"
      :purchasePriceList="draftData.purchasePriceList"
      :totalDraftingPrice="totalDraftingPrice"/> -->
    <!-- <DraftPurchasePriceAdd
      v-if="isCompleteStep"
      :statementTotalPrice="statementTotalPrice"
      :purchasePriceList="draftData.purchasePriceList"
      :totalDraftingPrice="totalDraftingPrice"
      :total-add-price="totalAddPrice"
      :total-purchase-price="totalPurchaseHistoryPrice"
      :isView="isView"
      @onClickPopPriceAdd="(targetData) => $emit('onClickPopPriceAdd', targetData)"
      @onClickPopPriceAddHistory="(item) => $emit('onClickPopPriceAddHistory', item)"
      @onClickPopStatementHistory="$emit('onClickPopStatementHistory')"
    /> -->
  </div>
</template>

<script>
import { getMoneyToNumber, addCommaForMoney } from "@/utils/stringUtils";
import DraftPurchasePrice from "./DraftPurchasePrice";
// import DraftPurchasePriceAdd from './DraftPurchasePriceAdd';
import { assetTypeName } from "@/_approval/const/const";
import { isEmpty, unescape } from "lodash";

export default {
  name: "DraftReqeustInfo",
  components: {
    DraftPurchasePrice,
    // DraftPurchasePriceAdd,
  },
  props: {
    draftData: Object,
    isCompleteStep: Boolean,
    isView: Boolean,
    statementTotalPrice: [Number, String],
    purchaseHistoryDataList: Array,
  },
  data() {
    return {
      assetTypeName: assetTypeName,
      assetTypeList: [
        // { code: 'G', name: '총무자산' },
        { code: "H", name: "일반구매" },
        { code: "I", name: "자산구매" },
      ],
      handleDataList: [
        "취급품목1",
        "취급품목2",
        "취급품목3",
        "취급품목4",
        "취급품목5",
        "취급품목6",
        "취급품목7",
      ],
    };
  },
  computed: {
    agitPath() {
      return "https://dkt.agit.in/g/300048996/wall/" + this.draftData.agitId;
    },
    totalDraftingPrice() {
      let result = 0;
      this.draftData.purchasePriceList.forEach((item) => {
        result += getMoneyToNumber(item.draftingPrice);
      });
      return addCommaForMoney(result);
    },
    totalAddPrice() {
      if (isEmpty(this.purchaseHistoryDataList)) {
        return "0";
      }

      const result = this.purchaseHistoryDataList.reduce(
        (accumulator, curValue) => accumulator + parseInt(curValue.price),
        0,
      );

      return addCommaForMoney(result);
    },
    totalPurchaseHistoryPrice() {
      if (isEmpty(this.draftData.purchasePriceList)) {
        return "0";
      }

      const result = this.draftData.purchasePriceList.reduce(
        (accumulator, curValue) => accumulator + parseInt(curValue.addPrice),
        0,
      );
      return addCommaForMoney(result);
    },
    contents() {
      return isEmpty(this.draftData.docContents)
        ? ""
        : unescape(unescape(this.draftData.docContents));
    },
  },
  methods: {
    handleNewLine(str) {
      return str.replace(/(?:\r\n|\r|\n)/g, "<br />");
    },
  },
};
</script>
