<template>
  <div :class="['item_choice', { disabled: isDisabled }]">
    <!-- disabled 상태시 disabled 클래스 추가 -->
    <input
      :id="id"
      v-model="input"
      :disabled="isDisabled"
      type="radio"
      class="inp_choice"
      :name="name"
      :value="value || id"
      @change="onChange"
    />
    <label class="lab_choice" :for="id"> <span class="ico_account ico_rdo" />{{ text }} </label>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
    text: String,
    name: String,
    id: undefined,
    selectedId: undefined,
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    input: {
      get() {
        return this.selectedId;
      },
      set(value) {
        this.$emit("update:selectedId", value);
      },
    },
  },
  methods: {
    onChange(e) {
      const from = this.selectedId;
      const to = e.target.value;

      this.$emit("onChange", to, from);
    },
  },
};
</script>
