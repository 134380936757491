<template>
  <tr>
    <td>{{ rowData.regDate | strDateTimeSplit }}</td>
    <td>{{ rowData.loginId }}({{ rowData.name }})</td>
    <td>{{ rowData.addPrice | currency }}</td>
    <td :class="['td_fold cell_left', { open: isOpen }]">
      <span
        class="txt_ellip"
        v-html="rowData.comment && rowData.comment.replace(/(\r\n|\n|\r)/g, '<br />')"
      />
      <span v-if="isEllip" class="ico_account ico_arr_ellip" @click.stop="isOpen = !isOpen"
        >펼치기 / 접기</span
      >
    </td>
  </tr>
</template>

<script>
export default {
  name: "PopPriceAddHistoryListLine",
  props: {
    rowData: Object,
    isEllip: Boolean,
  },
  data() {
    return {
      isOpen: false,
    };
  },
};
</script>
