let commToastMixin = {
  methods: {
    _showToast(param) {
      this.$store.commit("CommStore/showToast", param);
    },
    _closeToast() {
      this.$store.commit("CommStore/closeToast");
    },
  },
};

export default commToastMixin;
