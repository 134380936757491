<template>
  <div class="blank_asset5">
    <div class="head_tbl">
      <div class="pos_left">
        <strong class="tit_text">첨부파일</strong>
      </div>
    </div>
    <div class="tbl_comm tbl_view">
      <table>
        <!-- admin 타입 type_admin -->
        <caption class="ir_caption">
          첨부파일 표
        </caption>
        <colgroup>
          <col style="width: 192px" />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <th scope="row">파일첨부</th>
            <td style="height: 90px">
              <FileView :dataList.sync="attachFileList" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import FileView from "@/components/common/file/FileView";

export default {
  name: "DraftFile",
  components: {
    FileView,
  },
  props: {
    attachFileList: Array,
  },
};
</script>
