<template>
  <div class="popup_layer">
    <div class="inner_popup_layer" style="width: 944px">
      <div class="layer_head">
        <strong class="tit_popup">추가금액 내역보기</strong>
      </div>
      <div class="layer_body">
        <div class="tbl_comm tbl_list tbl_scroll">
          <div
            class="inner_scroll"
            style="height: calc(100vh - 316px); max-height: 343px; min-height: 49px"
          >
            <table>
              <colgroup>
                <col style="width: 142px" />
                <!-- 변경일시 -->
                <col style="width: 150px" />
                <!-- 변경자 -->
                <col style="width: 144px" />
                <!-- 추가금액 -->
                <col />
                <!-- 변경사유 -->
              </colgroup>
              <thead>
                <tr>
                  <th style="width: 142px">변경일시</th>
                  <th style="width: 150px">변경자</th>
                  <th style="width: 144px">추가금액</th>
                  <th style="width: 444px">변경사유</th>
                </tr>
              </thead>
              <tbody>
                <PopPriceAddHistoryListLine
                  v-for="(item, index) in histories"
                  :key="index"
                  :rowData="item"
                  :isEllip="isEllipList[index]"
                />
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="layer_foot">
        <div class="wrap_btn">
          <button
            type="button"
            class="btn_medium btn_fourthly"
            @click.prevent="$emit('onClickClose')"
          >
            닫기
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PopPriceAddHistoryListLine from "./PopPriceAddHistoryListLine";

export default {
  name: "PopPriceAddHistory",
  components: {
    PopPriceAddHistoryListLine,
  },
  props: {
    histories: Array,
  },
  data() {
    return {
      dataList: [
        {
          date: "2020-12-01 13:50",
          name: "kerry.youbo(유보현)",
          price: "100,000,000",
          reason:
            "입력된 변경사융가 노출됩니다. 입력된 변경사융가 노출됩니다. 입력된 변경사융가 노출됩니다. 입력된 변경사융가 노출됩니다.",
        },
        {
          date: "2020-12-01 13:50",
          name: "jason.jeong888 (김제일)",
          price: "100,000,000",
          reason:
            "입력된 변경사융가 노출됩니다. 입력된 변경사융가 노출됩니다. 입력된 변경사융가 노출됩니다. 입력된 변경사융가 노출됩니다.",
        },
        {
          date: "2020-12-01 13:50",
          name: "kerry.youbo(유보현)",
          price: "100,000,000",
          reason:
            "입력된 변경사융가 노출됩니다. 입력된 변경사융가 노출됩니다. 입력된 변경사융가 노출됩니다. 입력된 변경사융가 노출됩니다.",
        },
        {
          date: "2020-12-01 13:50",
          name: "kerry.youbo(유보현)",
          price: "100,000,000",
          reason:
            "입력된 변경사융가 노출됩니다. 입력된 변경사융가 노출됩니다. 입력된 변경사융가 노출됩니다. 입력된 변경사융가 노출됩니다.",
        },
        {
          date: "2020-12-01 13:50",
          name: "kerry.youbo(유보현)",
          price: "100,000,000",
          reason: "입력된 변경사융가 노출됩니다.",
        },
        {
          date: "2020-12-01 13:50",
          name: "kerry.youbo(유보현)",
          price: "100,000,000",
          reason: "입력된 변경사융가 노출됩니다.",
        },
        {
          date: "2020-12-01 13:50",
          name: "kerry.youbo(유보현)",
          price: "100,000,000",
          reason: "입력된 변경사융가 노출됩니다.",
        },
        {
          date: "2020-12-01 13:50",
          name: "kerry.youbo(유보현)",
          price: "100,000,000",
          reason: "입력된 변경사융가 노출됩니다.",
        },
        {
          date: "2020-12-01 13:50",
          name: "kerry.youbo(유보현)",
          price: "100,000,000",
          reason: "입력된 변경사융가 노출됩니다.",
        },
      ],
      isEllipList: [],
    };
  },
  watch: {
    histories(newVal, oldVal) {
      if (!newVal || newVal.length === 0) return;

      this.measureEllip();
    },
  },
  mounted() {
    this.measureEllip();
  },
  methods: {
    measureEllip() {
      this.$nextTick(function () {
        const $tdFoldItme = $(".td_fold");
        const $MaxWidth = $tdFoldItme.width();
        const $textWidths = $tdFoldItme.map(function () {
          $(this).removeClass("open");
          if ($(this)[0].className.indexOf("td_fold") > -1) {
            return $MaxWidth < $(this).children("span").width();
          } else {
            return false;
          }
        });
        this.isEllipList = $textWidths;
      });
    },
  },
};
</script>
