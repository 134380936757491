<template>
  <div class="popup_layer">
    <div class="inner_popup_layer" style="width: 864px">
      <div class="layer_head">
        <strong class="tit_popup">금액추가</strong>
      </div>
      <div class="layer_body">
        <div class="tbl_comm tbl_view">
          <table>
            <colgroup>
              <col style="width: 192px" />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th>추가금액</th>
                <td>
                  <InputAutoMoney
                    :value.sync="priceAddData.addPrice"
                    :maxLength="200"
                    placeholder="금액을 입력하세요"
                    @focusout="onFocusout"
                  />
                </td>
              </tr>
              <tr>
                <th>변경사유</th>
                <td>
                  <Textarea
                    style="height: 96px"
                    placeholder="변경사유를 입력하세요."
                    :max="300"
                    :value.sync="priceAddData.comment"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="tbl_foot">
        <p class="desc_tip">
          <span class="ico_account ico_exclam" />추가금액은 입력하신 금액만큼 누적 정산 합니다.
        </p>
      </div>
      <div class="layer_foot">
        <div class="wrap_btn">
          <button
            type="button"
            class="btn_medium btn_fourthly"
            @click.prevent="$emit('onClickClose')"
          >
            취소
          </button>
          <button
            type="button"
            class="btn_medium btn_medium btn_secondary"
            :disabled="isCompleteBtnDisabled"
            @click.once="onClickComplete"
          >
            완료
          </button>
        </div>
        <!-- <slot></slot> X(창닫기) -->
      </div>
    </div>
  </div>
</template>

<script>
import InputAutoMoney from "@/_approval/components/common/input/InputAutoMoney";
import Textarea from "@/_approval/components/common/textarea/Textarea";
import { getMoneyToNumber } from "@/utils/stringUtils";

export default {
  name: "PopPriceAdd",
  components: {
    InputAutoMoney,
    Textarea,
  },
  props: {
    priceAddTargetData: Object,
  },
  data() {
    return {
      priceAddData: {
        addPrice: "",
        comment: "",
      },
    };
  },
  computed: {
    isCompleteBtnDisabled() {
      return Boolean(!this.priceAddData.addPrice || !this.priceAddData.comment);
    },
  },
  methods: {
    onFocusout(e) {
      e.target.classList.remove("error");
    },
    onClickComplete() {
      this.$emit("onClickComplete", {
        addPrice: getMoneyToNumber(this.priceAddData.addPrice),
        comment: this.priceAddData.comment,
        projectCode: this.priceAddTargetData.projectCode,
      });
    },
  },
};
</script>
