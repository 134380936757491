<template>
  <!-- <a class="link_file" :href="toPath" download> -->
  <button type="button" class="btn_file" @click="onClick">
    <span :class="['ico_purchase', getClassByFileType]">{{ itemData.attachType }}</span>
    <span class="txt_file">{{ itemData.orgFile || itemData.reqTitle }}</span>
  </button>
</template>

<script>
// import ApiService from '@/services/ApiService'

// import { downloadFile } from '@/utils/fileUtils'

export default {
  name: "FileViewItem",
  props: {
    itemData: Object,
  },
  computed: {
    getClassByFileType() {
      const fileExt = this.itemData.attachExt;

      if (!fileExt) return "ico_etc";

      switch (fileExt.toLowerCase()) {
        case "pdf":
          return "ico_pdf";
        case "png":
          return "ico_png";
        case "jpg":
          return "ico_jpg";
        case "jpeg":
          return "ico_jpg";
        case "zip":
          return "ico_zip";
        default:
          return "ico_etc";
      }
    },
  },
  methods: {
    onClick() {
      this.$emit("onClickDownload", this.itemData);
      // const path = `${ this.$apiPath.ATTACH_FILE }/${ this.itemData.attachNum }`;

      // const result = await ApiService.shared.getFile( path );

      // downloadFile( result, this.itemData.orgFile );
    },
  },
};
</script>
