import moment from "moment";
import PageMixin from "@/mixins/PageMixin";
import { default as CommLayerMixin } from "@/_approval/mixins/common/commLayer";
import { docStatus, targetGubun, aprvlLineType } from "@/_approval/const/const";
import apiPath from "@/services/apiPath";
import ApiService from "@/services/ApiService";
import { escapeAmp } from "@/utils/stringUtils";
import LocalStorageManager from "@/LocalStorageManager";
import store from "@/store";

export default {
  mixins: [PageMixin, CommLayerMixin],
  methods: {
    strDateTimeSplitToOnlyDate(strDate) {
      const year = strDate.substr(0, 4);
      const month = strDate.substr(4, 2);
      const day = strDate.substr(6, 2);

      return `${year}-${month}-${day}`;
    },
    strDateTimeSplit(strDate) {
      const date = this.strDateTimeSplitToOnlyDate(strDate);
      const hour = strDate.substr(8, 2);
      const minutes = strDate.substr(10, 2);

      return `${date} ${hour}:${minutes}`;
    },
    checkNormalResponse(res) {
      if (res.code !== "200") {
        this.alert(res.text);
        return false;
      }
      return true;
    },
    makeBaseDocListPaymentParams(pagingConfig, searchForm) {
      const params = {
        targetGubun: targetGubun.STANDARD,
        page: pagingConfig.pageCurrent < 1 ? 1 : pagingConfig.pageCurrent,
        size: pagingConfig.pageRange,
        aprvlLineAccntNo: searchForm.selectedPayAccount.code,
      };

      if (searchForm.searchType) {
        params[this.searchForm.searchType.code] = this.searchForm.searchKeyword;
      }

      if (searchForm.draftFromDate && searchForm.draftToDate) {
        params.startDt = moment(this.searchForm.draftFromDate).format("YYYYMMDD"); //.replaceAll('-', '');
        params.endDt = moment(this.searchForm.draftToDate).format("YYYYMMDD"); //.replaceAll('-', '');
      }

      if (searchForm.completeFromDate && searchForm.completeToDate) {
        params.compStrDate = moment(this.searchForm.completeFromDate).format("YYYYMMDD"); //this.searchForm.completeFromDate.replaceAll('-', '');
        params.compEndDate = moment(this.searchForm.completeToDate).format("YYYYMMDD"); //this.searchForm.completeToDate.replaceAll('-', '');
      }

      return params;
    },
    makeBaseDocListParams(pagingConfig, searchForm) {
      const params = {
        targetGubun: targetGubun.STANDARD,
        page: pagingConfig.pageCurrent < 1 ? 1 : pagingConfig.pageCurrent,
        size: pagingConfig.pageRange,
        aprvlLineAccntNo: searchForm.selectedPayAccount.code,
      };

      if (searchForm.searchType) {
        params.searchType = this.searchForm.searchType.code;
        params.searchValue = this.searchForm.searchKeyword;
      }

      if (searchForm.draftFromDate && searchForm.draftToDate) {
        params.strDate = moment(this.searchForm.draftFromDate).format("YYYYMMDD"); //this.searchForm.draftFromDate.replaceAll('-', '');
        params.endDate = moment(this.searchForm.draftToDate).format("YYYYMMDD"); //this.searchForm.draftToDate.replaceAll('-', '');
      }

      if (searchForm.completeFromDate && searchForm.completeToDate) {
        params.compStrDate = moment(this.searchForm.completeFromDate).format("YYYYMMDD"); //this.searchForm.completeFromDate.replaceAll('-', '');
        params.compEndDate = moment(this.searchForm.completeToDate).format("YYYYMMDD"); //this.searchForm.completeToDate.replaceAll('-', '');
      }

      return params;
    },
    async fetchDeptPathNameByDeptCode(dept) {
      if (!dept) {
        return;
      }
      const result = await ApiService.shared.getData(`${this.$apiPath.APRVL_ORGDEPT}/${dept}`);

      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(text);
        return;
      }
      const { deptCode, deptName, deptPathCode, upDeptCode, deptPathName } = data;

      let ret = "";
      if (deptPathName) {
        let deptPathName = res.data.deptPathName;

        deptPathName = deptPathName.endsWith(";") ? deptPathName.slice(0, -1) : deptPathName;
        deptPathName = escapeAmp(deptPathName);
        ret = deptPathName.replaceAll(";", " - ");
      } else {
        ret = deptName;
      }

      return ret;
    },
    async fetchStoredDraftData(targetPk) {
      const apiUrl = `${this.$apiPath.APRVL_DOC_LIST}/${targetGubun.STANDARD}/${targetPk}`;
      const res = await ApiService.shared.getData(apiUrl);

      if (!this.checkNormalResponse(res)) {
        await this.$router.push(this.$routerPath.PAYMENT_DRAFT_TEMP);
        return;
      }

      const data = res.data;
      data.regDate = this.strDateTimeSplitToOnlyDate(data.regDate);

      return data;
    },
    async fetchStoredSettlementData(targetPk) {
      const apiUrl = `${this.$apiPath.APRVL_DOC_LIST}/${targetGubun.STANDARD}/${targetPk}`;
      const res = await ApiService.shared.getData(apiUrl);

      if (!this.checkNormalResponse(res)) {
        await this.$router.push(this.$routerPath.STATEMENT_DRAFT_TEMP);
        return;
      }

      const data = res.data;
      data.regDate = this.strDateTimeSplitToOnlyDate(data.regDate);

      return data;
    },
    async saveMyAccountInfoToVuex() {
      const userData = LocalStorageManager.shared.getUserData();
      const myDeptInfo = await this.fetchMyDepartment(userData.empNo);
      this.$store.commit("CommStore/login", {
        empNo: userData.empNo,
        empName: myDeptInfo.empName,
        accountId: userData.username,
        deptCode: myDeptInfo.deptCode,
        deptName: myDeptInfo.deptCodeName,
        email: myDeptInfo.email,
        mobile: userData.tel == null ? "-" : userData.tel,
        isAdmin: LocalStorageManager.shared.getIsAdmin(),
      });
    },
    async fetchMyDepartment(empNo) {
      const empInfo = empNo;
      const res = await ApiService.shared.getData(`${this.$apiPath.APRVL_ORGEMP}/${empInfo}`);

      if (parseInt(res.code) !== 200) {
        this._showLayer({ type: "confirm", content: res.text });
        return null;
      }

      return res.data;
    },
    getApprovalLineFilteredByType(lineType) {
      var unique = [];
      var size = this.approvalLine.length;

      unique.push(this.approvalLine[0]);
      for (var i = 0; i < size; i++) {
        var flag = true;
        unique.forEach((ele, num) => {
          if (
            this.approvalLine[i].accountId == ele.accountId &&
            this.approvalLine[i].aprvlLineType == ele.aprvlLineType
          ) {
            flag = false;
          }
        });
        if (flag == true) {
          unique.push(this.approvalLine[i]);
        }
      }

      this.approvalLine = unique;

      if (aprvlLineType.DRAFT == lineType) {
        return this.approvalLine
          .filter(
            (e) =>
              e.aprvlLineType === aprvlLineType.DRAFT || e.aprvlLineType === aprvlLineType.APPROVAL,
          )
          .filter((e) => e.prsntDeptYn === true)
          .sort((a, b) => a.aprvlOrder - b.aprvlOrder);
      }

      if (aprvlLineType.APPROVAL == lineType) {
        return this.approvalLine
          .filter((e) => e.aprvlLineType === aprvlLineType.APPROVAL)
          .filter((e) => e.prsntDeptYn === false)
          .sort((a, b) => a.aprvlOrder - b.aprvlOrder);
      }

      return this.approvalLine
        .filter((e) => e.aprvlLineType === lineType)
        .sort((a, b) => a.aprvlOrder - b.aprvlOrder);
    },
  },
};
